.About {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.About-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.About-photo {
    width: 75%;
    height: 75%;
}

.About-description {
    width: 80%;  /* Adjust this width as needed */
    padding: 20px;
}

.About-description h1 {
  text-align: center; /* Keeps "Hello!" centered */
}

.About-description p {
  text-align: justify;
  text-justify: inter-word; /* Justifies the paragraph text */
}

.About-description a {
    color: #1A3447; /* Normal link color */
    text-decoration: none; /* Removes underline from link */
}

.About-description a:hover {
    color: #152938; /* Link color on hover */
    text-decoration: underline; /* Adds underline on hover for better visibility */
}
