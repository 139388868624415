.navy-button {
  background-color: #1A3447; /* Navy background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding around the text */
  text-decoration: none; /* Remove underline from link */
  border: none; /* Remove border */
  cursor: pointer; /* Change mouse pointer to indicate clickable */
  border-radius: 5px; /* Optional: rounded corners */
  font-size: 16px; /* Adjust font size as needed */
  transition: background-color 0.3s ease; /* Smooth background color change on hover */
}

.navy-button:hover {
  background-color: #152938; /* Slightly darker navy on hover */
}

div {
  margin-top: 20px; /* Adjust the value as needed */
}
