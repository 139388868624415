.App {
  text-align: center;
  margin: 0 auto;
  max-width: 960px; /* Adjust based on your preference */
  padding: 20px;
}
.App-logo {
  height: auto; /* Adjust based on the logo's aspect ratio */
  width: 240px; /* Adjust the width as necessary */
}

.App-header {
  padding: 0px; /* Ensure there's enough space for the logo */
  display: flex; /* Align items vertically */
  align-items: center; /* Center-align logo vertically */
  justify-content: center; /* Center-align logo horizontally */
}

.App-main {
  margin-top: 0px;
}
